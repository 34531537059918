import React from 'react';
import ReactDOM from 'react-dom';
import "bulma/css/bulma.min.css"
import App from './App';
import './App.scss'


document.addEventListener("keydown", e =>
{
    if (e.key === "Enter" && !e.repeat)
    {
        if (document.fullscreenElement)
        {
            document.exitFullscreen()
        }
        else
        {
            document.querySelector("video").parentElement.requestFullscreen().catch(err =>
            {
                console.error(err)                
            })
        }
    }
})

ReactDOM.render(
    <React.StrictMode>
        <App/>
    </React.StrictMode>,
    document.getElementById('root')
);
