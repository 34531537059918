const HelpModal = ({Modal}) =>
(
    <Modal>
        <div className="fixed-grid has-3-cols">
        <div className="grid">
            <div className="cell">
                <article className="box notification">
                    <p><span className="shortcut-label">Lecture / Pause :</span> <span className="shortcut-key">Espace</span></p>
                </article>
                <article className="box notification">
                    <p><span className="shortcut-label">Avancer / Reculer (3s):</span> <span className="shortcut-key">← / →</span></p>
                    <p><span className="shortcut-label">Avancer / Reculer (10s):</span> <span className="shortcut-key">Shift + ← / →</span></p>
                    <p><span className="shortcut-label">Avancer / Reculer (30s):</span> <span className="shortcut-key">Ctrl + ← / →</span></p>
                    <p><span className="shortcut-label">Avancer / Reculer (5min):</span> <span className="shortcut-key">Ctrl +       Shift + ← / →</span></p>
                </article>
                <article className="box notification">
                    <p><span className="shortcut-label">Épisode Précédent / Suivant :</span> <span className="shortcut-key">Page Haut ⇞ / Page Bas ⇟</span></p>
                </article>
            </div>
            <div className="cell">
                <article className="box notification">
                    <p><span className="shortcut-label">Chapitre précédent :</span> <span className="shortcut-key">A</span></p>
                    <p><span className="shortcut-label">Chapitre suivant :</span> <span className="shortcut-key">Z</span></p>
                    <p><span className="shortcut-label">Chapitre au choix :</span> <span className="shortcut-key">0 1 2 3 ... 9</span></p>
                </article>
                <article className="box notification">
                    <p><span className="shortcut-label">Aller au début de l'épisode :</span> <span className="shortcut-key">Début ↖</span></p>
                </article>
                <article className="box notification">
                    <p><span className="shortcut-label">Aller au premier épisode :</span> <span className="shortcut-key">Ctrl + Début ↖</span></p>
                </article>
            </div>
            <div className="cell">
                <article className="box notification">
                    <p><span className="shortcut-label">Volume + / - :</span> <span className="shortcut-key">↑ / ↓</span></p>
                </article>
                <article className="box notification">
                    <p><span className="shortcut-label">Plein écran :</span> <span className="shortcut-key">Entrée</span></p>
                </article>
                <article className="box notification">
                    <p><span className="shortcut-label">Afficher / Cacher l'aide (cet écran) :</span> <span className="shortcut-key">F1</span></p>
                </article>
                <article className="box notification">
                    <p><span className="shortcut-label">Quitter Aide / Plein écran :</span> <span className="shortcut-key">Échap</span></p>
                </article>
            </div>
        </div>
        </div>
    </Modal>
)

export default HelpModal