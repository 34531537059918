import { useCallback, useRef } from 'react'
import { useDeepCompareEffect } from 'react-use'
import videojs from 'video.js'
import 'video.js/dist/video-js.css'


const useVideoJs = (videoJsOptions) =>
{
    const videoNode = useRef(null)
    const player = useRef(null)

    useDeepCompareEffect(() =>
    {
        player.current = videojs(videoNode.current, videoJsOptions)
        return () => player.current.dispose()
    }, [videoJsOptions])

    const Video = useCallback(({children, ...props}) =>
    (
        <div data-vjs-player>
            <video ref={videoNode} className="video-js vjs-big-play-centered" {...props}>
                {children}
            </video>
        </div>
    ), [])

    return {Video, player}
}

export default useVideoJs