import { useEffect, useState } from 'react';
import { useMount } from 'react-use';

const useKeyboardJs = (combination, downHandler, upHandler) =>
{
    const [keyboardJs, setKeyboardJs] = useState(null)

    useMount(() =>
    {
        import('keyboardjs').then((k) => setKeyboardJs(k.default || k))
    })

    useEffect(() =>
    {
        if (keyboardJs)
        {
            if (typeof downHandler !== "function")
                downHandler = (event) => {} // eslint-disable-line react-hooks/exhaustive-deps
            if (typeof upHandler !== "function")
                upHandler = (event) => {} // eslint-disable-line react-hooks/exhaustive-deps

            keyboardJs.bind(combination, downHandler, upHandler)

            return () =>
            {
                keyboardJs.unbind(combination, downHandler, upHandler)
            }   
        }
    }, [combination, downHandler, upHandler, keyboardJs])
}

export default useKeyboardJs;