import { useEffect, useRef } from "react"


const ZodeListItem = ({number, title, currentZode, playZode, updateZodeElement}) =>
{
    const currentElement = useRef(null)

    useEffect(() =>
    {
        updateZodeElement(number - 1, currentElement)
    }, [number, updateZodeElement])

    const onClick = e =>
    {
        e.preventDefault()
        playZode(number)
    }

    return (
        <li ref={currentElement}>
            <a className={[(number === currentZode ? "is-active" : "")]} onClick={onClick}> {/* eslint-disable-line*/}
                {number + " : " + title}
            </a>
        </li>
    )
}

export default ZodeListItem