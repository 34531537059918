import { useEffect, useState } from "react"
import { useEffectOnce, useInterval, useLifecycles, useList, useLocalStorage } from "react-use"
import useVideoJs from "./useVideoJs"
import useKeyboardJs from "./useKeyboardJs"
import ZodeList from "./ZodeList"
import useModal from "./useModal"
import HelpModal from "./HelpModal"
import useMobileDetect from "use-mobile-detect-hook"


const App = () =>
{
    const {Video, player} = useVideoJs({
        controls: true,
        playbackRates: [0.5, 1, 1.5, 2],
        fluid: true,
    })

    const [titlesList, {set: setTitles}] = useList([])
    const [zodeElementList, {updateAt: updateZodeElement}] = useList((() => {let retval = []; for (let i = 0; i < 64; ++i) retval = [...retval, null]; return retval;})())
    const [currentZode, setCurrentZode] = useState(1)

    useEffectOnce(() =>
    {
        fetch("https://assets.amestream.whiro.net/zodeTitles.json")
        .then(response => response.json())
        .then(jsonTitles => setTitles(jsonTitles))
    })

    const playZode = zodeNumber =>
    {
        // Si le player n'est pas valide, ça dégage (on peut rien faire)
        if (!player?.current) return

        // Se souvient de l'état (lecture en cours ?) du player
        const wasPlaying = !player.current.paused()

        // Traite le nom pour rajouter un 0 au début
        let strNumber = zodeNumber.toString()
        while (strNumber.length < 2) strNumber = "0" + strNumber

        // Change la vidéo
        player.current.src("https://assets.amestream.whiro.net/zodes/" + strNumber + ".webm")

        // Change la piste de sous-titre
        const textTracks = player.current.textTracks()
        while (textTracks.length !== 0)
            textTracks.removeTrack(textTracks[0])
        player.current.addRemoteTextTrack(
            {
                "src": "https://assets.amestream.whiro.net/zodes/" + strNumber + ".vtt",
                "default": true,
                "kind": "subtitles",
                "srcLang": "fr",
                "label": "French",
            }, true
        )
        player.current.addRemoteTextTrack(
            {
                "src": "https://assets.amestream.whiro.net/zodes/" + strNumber + ".chapters",
                "default": true,
                "kind": "chapters",
                "label": "Chapitres",
            }, true
        )

        // Change le zode courant
        setCurrentZode(zodeNumber)

        // Scroll vers le bon item dans la liste des zodes
        zodeElementList[zodeNumber - 1]?.current?.scrollIntoView(
            {
                "behavior": "smooth",
                "block": "center",
                "inline": "center",
            }
        )

        // Remets lecture si c'était en cours de lecture
        if (wasPlaying)
            player.current.play()
    }

    const playZodeRelative = zodeOffset =>
    {
        const newZodeNumber = currentZode + zodeOffset
        if (1 <= newZodeNumber && newZodeNumber <= 64)
        {
            playZode(newZodeNumber)
        }
    }

    const seek = (time, relative) =>
    {
        if (player?.current)
        {
            if (relative)
            {
                let currentTime = player.current.currentTime()
                player.current.currentTime(currentTime + time)
            }
            else
            {
                player.current.currentTime(time)
            }
        }
    }

    const getChaptersTimecodes = () =>
    {
        if (player?.current)
        {
            const textTracks = player.current.textTracks()
            for (let i = 0; i < textTracks.length; ++i) // NOTE: Car ce type n'est pas itérable avec un for...of
            {
                const textTrack = textTracks[i]
                if (textTrack.kind === "chapters")
                {
                    const timecodes = []
                    for (let j = 0; j < textTrack.cues.length; ++j) // NOTE: Car ce type n'est pas itérable avec un for...of
                    {
                        const cue = textTrack.cues[j]
                        timecodes.push(cue.startTime)
                    }
                    return timecodes
                }
            }
        }
        else
            return []
    }

    const seekChapter = number =>
    {
        const timecodes = getChaptersTimecodes()
        if (number < timecodes.length)
        {
            seek(timecodes[number], false)
        }
    }

    const seekNextChapter = () =>
    {
        if (player?.current)
        {
            const timecodes = getChaptersTimecodes()
            const currentTime = player.current.currentTime()
            for (let i = 0; i < timecodes.length; ++i) // NOTE: Car ce type n'est pas itérable avec un for...of
            {
                const timecode = timecodes[i]
                if (timecode > currentTime)
                {
                    seek(timecode, false)
                    return
                }
            }
        }
    }

    const seekPreviousChapter = (threshold = 4) =>
    {
        if (player?.current)
        {
            const timecodes = getChaptersTimecodes()
            const currentTime = player.current.currentTime()
            for (let i = timecodes.length; i >= 0; --i) // NOTE: Car ce type n'est pas itérable avec un for...of, ET EN PLUS là de toute façon on pouvait pas, parce qu'il fallait itérer à l'envers
            {
                const timecode = timecodes[i]
                if (timecode + threshold < currentTime)
                {
                    seek(timecode, false)
                    return
                }
            }
            seek(0, false)
        }
    }

    const togglePause = () =>
    {
        if (player?.current)
        {
            if (player.current.paused())
                player.current.play()
            else
                player.current.pause()
        }
    }

    const toggleMute = () =>
    {
        if (player?.current)
        {
            const currentState = player?.current?.muted()
            player?.current?.muted(!currentState)
        }
    }

    const changeVolume = offset =>
    {
        if (player?.current)
        {
            const currentVolume = player.current.volume()
            let newVolume = currentVolume + offset
            newVolume = newVolume > 100 ? 100 : newVolume < 0 ? 0 : newVolume // NOTE: on assure 0 <= newVolume <= 100
            player.current.volume(newVolume)
        }
    }

    const [savedZode, setSavedZode] = useLocalStorage("Amestream/SavedZode", null)
    const [savedTime, setSavedTime] = useLocalStorage("Amestream/SavedTime", null)

    const saveValues = () =>
    {
        // Enregistre le zode actuel
        setSavedZode(currentZode)

        // Enregistre le temps actuel
        if (player.current)
        {
            try
            {
                setSavedTime(player.current.currentTime())
            }
            catch (e)
            {
                console.error("Error calling currentTime() on player")
            }
        }
        else
            setSavedTime(null)
    }
    useInterval(saveValues, 5000)

    const loadValues = () =>
    {
        // Charge le zode actuel
        playZode(savedZode || 1)

        // Charge le timecode actuel
        if (savedTime && player?.current)
        {
            seek(savedTime, false)
        }
    }

    useLifecycles(loadValues, saveValues)

    useEffect(() =>
    {
        if (!player?.current) return

        const endedHandler = () =>
        {
            if (player?.current)
            {
                playZodeRelative(+1)
                togglePause()
            }
        }
        player.current.on("ended", endedHandler)

    }/*, [playZodeRelative, player]*/) // TODO: à remettre

    const {Modal: HelpModalInternal, hide: hideHelp, toggle: toggleHelp} = useModal()

    useKeyboardJs("space", e => {e.preventDefault(); e.preventRepeat(); togglePause();})
    useKeyboardJs("left", () => seek(-3, true))
    useKeyboardJs("right", () => seek(+3, true))
    useKeyboardJs("shift + left", e => {e.preventDefault(); seek(-10, true);})
    useKeyboardJs("shift + right", e => {e.preventDefault(); seek(+10, true);})
    useKeyboardJs("ctrl + left", () => seek(-30, true))
    useKeyboardJs("ctrl + right", () => seek(+30, true))
    useKeyboardJs("ctrl + shift + left", () => seek(-300, true))
    useKeyboardJs("ctrl + shift + right", () => seek(+300, true))
    useKeyboardJs("pageup", e => {if (!e.repeat) playZodeRelative(-1);})        // NOTE: Parce que là apparemment e.preventRepeat() ne marchait pas
    useKeyboardJs("pagedown", e => {if (!e.repeat) playZodeRelative(+1);})      // (donc on utilise le membre booléen repeat qui est un membre standard de KeyboardEvent)
    useKeyboardJs("home", () => seek(0, false))
    useKeyboardJs("ctrl + home", () => playZode(1))
    // NOTE: Cf. index.js pour le event handler du fullscreen, qui m'a bien cassé les couilles. (Alt+Entrée ne marche pas, seulement Entrée, et pas en React. Merci la Fullscreen API.)
    useKeyboardJs("a", () => seekPreviousChapter())
    useKeyboardJs("z", () => seekNextChapter())
    useKeyboardJs(["0", "1", "2", "3", "4", "5", "6", "7", "8", "9",
                   "num0", "num1", "num2", "num3", "num4", "num5", "num6", "num7", "num8", "num9"], e =>
    {
        seekChapter(parseInt(e.key))
    })
    useKeyboardJs("m", () => toggleMute())
    useKeyboardJs("up", e => {e.preventDefault(); changeVolume(+0.05);})
    useKeyboardJs("down", e => {e.preventDefault(); changeVolume(-0.05);})
    useKeyboardJs("f1", e => {e.preventDefault(); toggleHelp();})
    useKeyboardJs("escape", e => {e.preventDefault(); hideHelp();})

    const detectMobile = useMobileDetect()

    return (
        <>
            <nav className="navbar">
                <div className="navbar-item is-size-4 amestream">
                    Amestream
                </div>
                <div className="navbar-item is-size-5">
                    Fullmetal Alchemist Brotherhood en streaming !
                </div>
            </nav>
            <div className="columns wrapper">
                <div className="column">
                    <Video/>
                    {
                        (() =>
                        {
                            if (detectMobile.isDesktop())
                            {
                                return (
                                    <div className="box help-disclaimer">
                                        <p className="help-disclaimer">Appuyez sur F1 pour avoir de l'aide sur les touches</p>
                                    </div>
                                )
                            }
                        })()
                    }
                </div>
                <div className="column is-3" style={{"overflowY": "scroll"}}>
                    <ZodeList titles={titlesList} currentZode={currentZode} playZode={playZode}
                        updateZodeElement={updateZodeElement}/>
                </div>
            </div>
            <HelpModal Modal={HelpModalInternal}/>
        </>
    )
}

export default App
