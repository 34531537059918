import { useCallback, useState } from "react"


const useModal = () =>
{
    const [active, setActive] = useState(false)

    const show = useCallback(() =>
    {
        setActive(true)
    }, [])

    const hide = useCallback(() =>
    {
        setActive(false)
    }, [])

    const toggle = useCallback(() =>
    {
        setActive(!active)
    }, [active])

    const Modal = useCallback(({children}) =>
        <div className={"modal" + (active ? " is-active" : "")}>
            <div className="modal-background" onClick={hide}></div>
            <div className="modal-content">
                {children}
            </div>
            {/* <button class="modal-close is-large" aria-label="close" onClick={hide}></button> */}
        </div>
    , [active, hide])

    return {Modal, active, setActive, show, hide, toggle}
}

export default useModal