import ZodeListItem from "./ZodeListItem"


const ZodeList = ({titles, currentZode, playZode, updateZodeElement}) =>
{
    return (
        <aside className="menu">
            <p className="menu-label">Liste des épisodes</p>
            <ul className="menu-list">
                {
                    (() =>
                    {
                        let retValue = []
                        for (let i = 1; i <= titles.length ; ++i)
                        {
                            retValue = [...retValue, <ZodeListItem number={i} title={titles[i-1]} key={i}
                                                        currentZode={currentZode} playZode={playZode}
                                                        updateZodeElement={updateZodeElement}/>]
                        }
                        return retValue
                    })()
                }
            </ul>
        </aside>
    )
}

export default ZodeList